<template>
    <div>
        <!-- Início principal -->
        <v-card>
            <v-toolbar
                flat
            >
                <v-icon>
                    fas fa-file-invoice
                </v-icon>
                <v-toolbar-title
                    class="pl-4"
                >
                    Importação de retorno (Correios)
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-app-bar-nav-icon @click="fechar">
                    <v-icon>fas fa-times</v-icon>
                </v-app-bar-nav-icon>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col
                        class="px-6 py-6"
                    >
                        <input type="file" @change="selectFile($event)" ref="arquivo">
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                        cols="12"
                        md="12"
                    >
                    <v-textarea
                        outlined
                        filled
                        v-model="message"
                        :label="label"
                        :readonly="true"
                        rows="12"
                        cols="148"
                    ></v-textarea>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <!-- Fim principal -->
    </div>
</template>

<script>
import { baseApiUrl, showError, showSuccess } from '@/global'
import axios from 'axios'

export default {
    name: 'ImportacaoRetornoCorreios',
    data: () => ({
        arquivo: '',
        message: [],
        label: 'Nenhum arquivo processado.'
    }),
    methods: {
        fechar () {
            this.$router.push('/')
        },
        selectFile(event) {
            let file = event.target.files[0];
            let config = { header : { 'Content-Type' : 'multipart/form-data' } }
            let data = new FormData()
            data.append('arquivo', file)
            let url = `${baseApiUrl}/retorno-correios`
            axios.post(url, data, config)
                .then(res => {
                    if (res.data.retorno) {
                        this.label = "Arquivo processado:- " + file.name
                        this.message = "\n" + res.data.data;
                        showSuccess(res.data.mensagem)
                    } else {
                        this.message = "\nError\n"
                        this.message += res.data.mensagem;
                        showError(res.data.mensagem)
                    }
                })
                .catch(error => {
                    showError(error)
                })
                .finally(() => this.$refs.arquivo.value=null )
        }
    }
}
</script>